import { useEffect, useState } from 'react';

import { CONFIGS } from '../utilities/Configs';
import { FormatDate, UrlQueryParam } from '../utilities/Helpers';
import { Faucet, Keg } from '../utilities/Types';

import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import IconDelete from '@material-ui/icons/Delete';
import TextField from '@material-ui/core/TextField';

import { KegAdd } from './KegAdd';

type FaucetEditProps = {
	faucet: Faucet,
	onEdit: () => void,
	onDeleteKeg: () => void,
	onClose: () => void
};

export const FaucetEdit = (props: FaucetEditProps) => {
	const [faucetData, setFaucetData] = useState<undefined | { brands: Array<any>, distributors: Array<any>, kegs: Array<any> }>(undefined);
	const [kegs, setKegs] = useState<undefined | Array<Keg>>(undefined);
	const [form, setForm] = useState<any>(undefined);
	const [isKegAddDialogOpen, setIsKegAddDialogOpen] = useState<boolean>(false);
	
	useEffect(() => {
        fetch(CONFIGS.API_ENDPOINTS.LOCATION_FAUCET_SELECTION_LISTS_GET + btoa(JSON.stringify([UrlQueryParam('token'), UrlQueryParam('id')]))).then(
            (response) => response.json()
        ).then(
            (response) => {
				setFaucetData({
					brands: response.result.Brands,
					distributors: response.result.Distributors,
					kegs: response.result.Kegs
				});

				setForm({
					brandId: (props.faucet['bid'] || '')
				});
			}
        ).catch(
            () => alert('An unexpected error has occurred. Please try again later. If problem persists, please contact support.')
        );

        fetch(CONFIGS.API_ENDPOINTS.KEG_HISTORY_GET + btoa(JSON.stringify([UrlQueryParam('token'), UrlQueryParam('id'), props.faucet['fid']]))).then(
            (response) => response.json()
        ).then(
            (response) => setKegs(response['result'])
        ).catch(
            () => alert('An unexpected error has occurred. Please try again later. If problem persists, please contact support.')
        );
	}, []);
	
	const handleFormChange = (data) => {
		setForm({ ...form, ...data });
	};

	const handleFormSubmit = () => {
		updateFaucet(props.faucet['kid']);
	};

	const removeKeg = () => {
		if (window.confirm('Are you sure?') === true) {
			const post = [
				UrlQueryParam('id'),
				props.faucet['fid']
			];

			fetch(CONFIGS.API_ENDPOINTS.KEG_DELETE + btoa(JSON.stringify([...[UrlQueryParam('token')], ...post]))).then(
				(response) => response.json()
			).then(
				(response) => {
					if (response['error'] !== undefined) {
						alert('Error: ' + response['error']['message']);
					}
					else {
						props.onDeleteKeg();
						props.onClose();
					}
				}
			).catch(
				() => alert('An unexpected error has occurred. Please try again later. If problem persists, please contact support.')
			);
		}
	};

	const updateFaucet = (kegId) => {
		const post = [
			UrlQueryParam('id'),
			props.faucet['fid'],
			(props.faucet['did'] || -1),
			(form.brandId || -1),
			(form.brandId !== props.faucet['bid'] ? -1 : (kegId || -1))
		];

		fetch(CONFIGS.API_ENDPOINTS.FAUCET_UPDATE + btoa(JSON.stringify([...[UrlQueryParam('token')], ...post]))).then(
			(response) => response.json()
		).then(
			(response) => {
				if (response['error'] !== undefined) {
					alert('Error: ' + response['error']['message']);
				}
				else {
					props.onEdit();
					props.onClose();
				}
			}
		).catch(
			() => alert('An unexpected error has occurred. Please try again later. If problem persists, please contact support.')
		);
	};
	
	return (
		(faucetData === undefined || kegs === undefined || form === undefined) ? (
			<Dialog open>
				<DialogContent>
					Loading...
				</DialogContent>
			</Dialog>
		) : (
			<Dialog open maxWidth="sm" fullWidth>
				<DialogTitle>Manage Faucet</DialogTitle>
				<DialogContent dividers>
					<Grid container spacing={1}>
						<Grid item xs={12}>
							<Autocomplete
								options={faucetData.brands}
								filterOptions={createFilterOptions({
									limit: CONFIGS.FAUCET_EDIT.BRANDS_LIMIT,
									trim: true
								})}
								value={faucetData.brands.find((_brand) => (_brand['Key'] === form.brandId))}
								getOptionLabel={(_brand) => _brand['Value']}
								renderInput={
									(params) => (
										<TextField
											{...params}
											label="Brand"
											placeholder={(faucetData.brands.length > CONFIGS.FAUCET_EDIT.BRANDS_LIMIT ? 'Refine search, top ' + CONFIGS.FAUCET_EDIT.BRANDS_LIMIT + ' results shown...' : 'Select...')}
											InputLabelProps={{
												shrink: true
											}}
											variant="outlined"
											margin="dense"
										/>
									)
								}
								onChange={(_event, _brand) => (_brand !== null ? handleFormChange({ brandId: _brand['Key'] }) : {})}
							/>
						</Grid>
						<Grid item xs={12}>
							<div className="row row-spread">
								<p className="bold">Keg</p>
								<div>
									<IconButton color="secondary" disabled={(props.faucet['kid'] === null)} onClick={removeKeg}>
										<IconDelete />
									</IconButton>
									&nbsp;
									<Button variant="contained" color="primary" disabled={props.faucet['bid'] === null} onClick={() => setIsKegAddDialogOpen(true)}>New Keg</Button>
								</div>
							</div>
						</Grid>
						<Grid item xs={12}>
							<table>
								<thead>
									<tr>
										<th width="25%">Size</th>
										<th width="25%">Installed</th>
										<th width="25%">Born</th>
										<th width="25%">Expires</th>
									</tr>
								</thead>
								<tbody>
									{
										kegs.length === 0 ? (
											<tr>
												<td colSpan={4}>None</td>
											</tr>
										) : (
											kegs.map((_keg, i) => (
												<tr key={i}>
													<td>
														{
															(() => {
																switch(_keg['keg_size']) {
																	case 1:
																		return 'Small';

																	case 2:
																		return 'Large';
																		
																	default:
																		return 'None';
																}
															})()
														}
													</td>
													<td>{_keg['tapped_date'] !== null ? FormatDate(new Date(_keg['tapped_date'])) : '-'}</td>
													<td>{_keg['born_on_date'] !== null ? FormatDate(new Date(_keg['born_on_date'])) : '-'}</td>
													<td>{_keg['expiration_date'] !== null ? FormatDate(new Date(_keg['expiration_date'])) : '-'}</td>
												</tr>
											))
										)
									}
								</tbody>
							</table>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Button variant="contained" color="default" onClick={props.onClose}>Cancel</Button>
					<Button variant="contained" color="primary" onClick={handleFormSubmit}>Save</Button>
				</DialogActions>
				{
					isKegAddDialogOpen === true && (
						<KegAdd faucet={props.faucet} brand={faucetData.brands.find((_brand) => (_brand['Key'] === props.faucet['bid']))} onAdd={(_kegId) => updateFaucet(_kegId)} onClose={() => setIsKegAddDialogOpen(false)} />
					)
				}
			</Dialog>
		)
	);
};