import { useState } from 'react';

import { CONFIGS } from '../utilities/Configs';
import { UrlQueryParam } from '../utilities/Helpers';
import { Currencies } from '../utilities/Lists';
import { PartContainer, PartGroup } from '../utilities/Types';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';

type PartAddProps = {
	container: PartContainer,
	partGroups: Array<PartGroup>,
	onAdd: () => void,
	onClose: () => void
};

export const PartAdd = (props: PartAddProps) => {
	const [form, setForm] = useState<any>({
		partGroup: '',
		partGroupInventoryItem: '',
		name: '',
		partNumber: '',
		serialNumber: '',
		cost: '',
		currency: '',
		dateInstalled: '',
		dateServiced: '',
		serviceInterval: '',
		sortOrder: '',
		active: '1'
	});
	
	const handleFormChange = (data) => {
		setForm({ ...form, ...data });
	};
	
	const handleFormSubmit = () => {
		const post = [
			-1,
			props.container['type'],
			props.container['id'],
			(parseInt(form.partGroupInventoryItem) || -1),
			form.name,
			form.partNumber,
			form.serialNumber,
			(parseInt(form.cost) || 0),
			(parseInt(form.currency) || -1),
			(form.dateInstalled !== '' ? new Date(form.dateInstalled) : new Date('0001-01-01')),
			(form.dateServiced !== '' ? new Date(form.dateServiced) : new Date('0001-01-01')),
			(parseInt(form.serviceInterval) || 0),
			(parseInt(form.sortOrder) || 0),
			parseInt(form.active)
		];

		fetch(CONFIGS.API_ENDPOINTS.PART_UPSERT + btoa(JSON.stringify([...[UrlQueryParam('token')], ...post]))).then(
            (response) => response.json()
        ).then(
            (response) => {
				if (response['error'] !== undefined) {
					alert('Error: ' + response['error']['message']);
				}
				else {
					props.onAdd();
					props.onClose();
				}
			}
        ).catch(
            () => alert('An unexpected error has occurred. Please try again later. If problem persists, please contact support.')
        );
	};
	
	return (
		<Dialog open maxWidth="sm" fullWidth>
			<DialogTitle>Add Part</DialogTitle>
			<DialogContent dividers>
				<Grid container spacing={1}>
					<Grid item xs={12}>
						<TextField
							name="partGroup"
							value={form.partGroup}
							label="Parts Groups"
							select
							InputLabelProps={{
								shrink: true
							}}
							variant="outlined"
							margin="dense"
							fullWidth
							onChange={(event) => handleFormChange({ [event.target.name]: event.target.value, partGroupInventoryItem: '' })}
						>
							<MenuItem value="" disabled>Select...</MenuItem>
							{
								props.partGroups.map((_partGroup, i) => (
									<MenuItem key={i} value={_partGroup['Parts Group ID']}>{_partGroup['Parts Group Name']}</MenuItem>
								))
							}
						</TextField>
					</Grid>
					<Grid item xs={12}>
						<TextField
							name="partGroupInventoryItem"
							value={form.partGroupInventoryItem}
							label="Parts Inventory"
							select
							InputLabelProps={{
								shrink: true
							}}
							variant="outlined"
							margin="dense"
							fullWidth
							onChange={(event) => handleFormChange({ [event.target.name]: event.target.value })}
						>
							<MenuItem value="" disabled>Select...</MenuItem>
							{
								form.partGroup !== '' && (
									props.partGroups.find((_partGroup) => _partGroup['Parts Group ID'] === form.partGroup)['Parts Inventory'].map((_partGroupItem, i) => (
										<MenuItem key={i} value={_partGroupItem['Parts Inventory ID']}>{_partGroupItem['Parts Inventory Name']}</MenuItem>
									))
								)
							}
						</TextField>
					</Grid>
					<Grid item xs={12}>
						<TextField
							name="name"
							value={form.name}
							label="Name"
							InputLabelProps={{
								shrink: true
							}}
							variant="outlined"
							margin="dense"
							fullWidth
							onChange={(event) => handleFormChange({ [event.target.name]: event.target.value })}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							name="partNumber"
							value={form.partNumber}
							label="Part No."
							InputLabelProps={{
								shrink: true
							}}
							variant="outlined"
							margin="dense"
							fullWidth
							onChange={(event) => handleFormChange({ [event.target.name]: event.target.value })}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							name="serialNumber"
							value={form.serialNumber}
							label="Serial"
							InputLabelProps={{
								shrink: true
							}}
							variant="outlined"
							margin="dense"
							fullWidth
							onChange={(event) => handleFormChange({ [event.target.name]: event.target.value })}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<TextField
							name="cost"
							value={form.cost}
							type="number"
							label="Cost"
							InputLabelProps={{
								shrink: true
							}}
							variant="outlined"
							margin="dense"
							fullWidth
							onChange={(event) => handleFormChange({ [event.target.name]: event.target.value })}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<TextField
							name="currency"
							value={form.currency}
							label="Currency"
							select
							InputLabelProps={{
								shrink: true
							}}
							variant="outlined"
							margin="dense"
							fullWidth
							onChange={(event) => handleFormChange({ [event.target.name]: event.target.value })}
						>
							<MenuItem value="" disabled>Select...</MenuItem>
							{
								Currencies.map((_currency, i) => (
									<MenuItem key={i} value={_currency.id}>{_currency.code}</MenuItem>
								))
							}
						</TextField>
					</Grid>
					<Grid item xs={12} md={6}>
						<TextField
							name="dateInstalled"
							value={form.dateInstalled}
							type="date"
							label="Install Date"
							InputLabelProps={{
								shrink: true
							}}
							variant="outlined"
							margin="dense"
							fullWidth
							onChange={(event) => handleFormChange({ [event.target.name]: event.target.value })}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<TextField
							name="dateServiced"
							value={form.dateServiced}
							type="date"
							label="Last Service Date"
							InputLabelProps={{
								shrink: true
							}}
							variant="outlined"
							margin="dense"
							fullWidth
							onChange={(event) => handleFormChange({ [event.target.name]: event.target.value })}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<TextField
							name="serviceInterval"
							value={form.serviceInterval}
							type="number"
							label="Service Interval"
							InputLabelProps={{
								shrink: true
							}}
							variant="outlined"
							margin="dense"
							fullWidth
							onChange={(event) => handleFormChange({ [event.target.name]: event.target.value })}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<TextField
							name="sortOrder"
							value={form.sortOrder}
							type="number"
							label="Sort Order"
							InputLabelProps={{
								shrink: true
							}}
							variant="outlined"
							margin="dense"
							fullWidth
							onChange={(event) => handleFormChange({ [event.target.name]: event.target.value })}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<TextField
							name="active"
							value={form.active}
							label="Active"
							select
							InputLabelProps={{
								shrink: true
							}}
							variant="outlined"
							margin="dense"
							fullWidth
							onChange={(event) => handleFormChange({ [event.target.name]: event.target.value })}
						>
							<MenuItem value="1">Yes</MenuItem>
							<MenuItem value="0">No</MenuItem>
						</TextField>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button variant="contained" color="default" onClick={props.onClose}>Cancel</Button>
                <Button variant="contained" color="primary" onClick={handleFormSubmit}>Save</Button>
			</DialogActions>
		</Dialog>
	);
};