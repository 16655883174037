import { useState } from 'react';

import { CONFIGS } from '../utilities/Configs';
import { UrlQueryParam } from '../utilities/Helpers';
import { Faucet } from '../utilities/Types';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';

type KegAddProps = {
	faucet: Faucet,
	brand: any,
	onAdd: (kegId: number) => void,
	onClose: () => void
};

export const KegAdd = (props: KegAddProps) => {
	const [form, setForm] = useState<any>({
		kegSize: 1,
		dateBorn: '',
		dateExpires: '',
		dateTapped: (new Date()).toISOString().substr(0, 10)
	});
	
	const handleFormChange = (data) => {
		setForm({ ...form, ...data });
	};

	const handleFormSubmit = () => {
		const post = [
			UrlQueryParam('id'),
			-1,
			(props.faucet['bid'] || -1),
			props.faucet['fid'],
			(form.dateBorn !== '' ? new Date(form.dateBorn) : '0001-01-01'),
			(form.dateExpires !== '' ? new Date(form.dateExpires) : '0001-01-01'),
			(form.dateTapped !== '' ? new Date(form.dateTapped) : '0001-01-01'),
			(form.kegSize || 0)
		];
		
		fetch(CONFIGS.API_ENDPOINTS.KEG_UPSERT + btoa(JSON.stringify([...[UrlQueryParam('token')], ...post]))).then(
			(response) => response.json()
		).then(
			(response) => {
				if (response['error'] !== undefined) {
					alert('Error: ' + response['error']['message']);
				}
				else {
					props.onAdd(response.result);
					props.onClose();
				}
			}
		).catch(
			() => alert('An unexpected error has occurred. Please try again later. If problem persists, please contact support.')
		);
	};
	
	return (
		<Dialog open maxWidth="sm" fullWidth>
			<DialogTitle>New Keg</DialogTitle>
			<DialogContent dividers>
				<Grid container spacing={1}>
					{
						props.brand !== undefined && (
							<Grid item xs={12}>
								<p className="mute">Brands</p>
								<p className="bold">{props.brand['Value']}</p>
							</Grid>
						)
					}
					<Grid item xs={12}>
						<TextField
							name="kegSize"
							value={form.kegSize}
							label="Keg Size"
							select
							InputLabelProps={{
								shrink: true
							}}
							variant="outlined"
							margin="dense"
							fullWidth
							onChange={(event) => handleFormChange({ [event.target.name]: event.target.value })}
						>
							<MenuItem value={0}>None</MenuItem>
							<MenuItem value={1}>Small</MenuItem>
							<MenuItem value={2}>Large</MenuItem>
						</TextField>
					</Grid>
					<Grid item xs={12}>
						<TextField
							name="dateTapped"
							value={form.dateTapped}
							type="date"
							label="Installed On"
							InputLabelProps={{
								shrink: true
							}}
							variant="outlined"
							margin="dense"
							fullWidth
							onChange={(event) => handleFormChange({ [event.target.name]: event.target.value })}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							name="dateBorn"
							value={form.dateBorn}
							type="date"
							label="Born On"
							InputLabelProps={{
								shrink: true
							}}
							variant="outlined"
							margin="dense"
							fullWidth
							onChange={(event) => handleFormChange({ [event.target.name]: event.target.value })}
						/>
					</Grid>
					<Grid item xs={12}>
						<TextField
							name="dateExpires"
							value={form.dateExpires}
							type="date"
							label="Expires On"
							InputLabelProps={{
								shrink: true
							}}
							variant="outlined"
							margin="dense"
							fullWidth
							onChange={(event) => handleFormChange({ [event.target.name]: event.target.value })}
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button variant="contained" color="default" onClick={props.onClose}>Cancel</Button>
				<Button variant="contained" color="primary" onClick={handleFormSubmit}>Save</Button>
			</DialogActions>
		</Dialog>
	);
};