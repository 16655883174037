const ENVIRONMENT = (
    window.location.hostname === 'dlts.draftlinesmartsystem.com' ? {
        SERVER_ADDRESS: 'https://draftlinesmartsystem.com/'
    } : {
        SERVER_ADDRESS: 'https://devbdp.webthink.com/'
    }
);

export const CONFIGS = {
    API_ENDPOINTS: {
        FAUCET_UPDATE: ENVIRONMENT.SERVER_ADDRESS + '/JsonRPC.aspx?id=186&method=TechnicianAPIver2.UpdateFaucet&params=',
        LOCATION_FAUCET_SELECTION_LISTS_GET: ENVIRONMENT.SERVER_ADDRESS + '/JsonRPC.aspx?id=186&method=TechnicianAPIver2.GetSelectionData&params=',
        KEG_DELETE: ENVIRONMENT.SERVER_ADDRESS + '/JsonRPC.aspx?id=186&method=TechnicianAPIver2.RemoveKeg&params=',
        KEG_HISTORY_GET: ENVIRONMENT.SERVER_ADDRESS + '/JsonRPC.aspx?id=186&method=TechnicianAPIver2.GetKegsHistory&params=',
        KEG_UPSERT: ENVIRONMENT.SERVER_ADDRESS + '/JsonRPC.aspx?id=186&method=TechnicianAPIver2.InsertOrUpdateKeg&params=',
        LOCATIONS_GET: ENVIRONMENT.SERVER_ADDRESS + '/JsonRPC.aspx?id=186&method=TechnicianAPIver2.GetSystemWithParts&params=',
        PART_GET: ENVIRONMENT.SERVER_ADDRESS + '/JsonRPC.aspx?id=186&method=TechnicianAPIver2.GetPart&params=',
        PART_GROUPS_INVENTORIES_GET: ENVIRONMENT.SERVER_ADDRESS + '/JsonRPC.aspx?id=186&method=TechnicianAPIver2.GetPartsGroupsWithInventory&params=',
        PART_UPSERT: ENVIRONMENT.SERVER_ADDRESS + '/JsonRPC.aspx?id=186&method=TechnicianAPIver2.InsertOrUpdatePart&params=',
        SETTINGS_GET: ENVIRONMENT.SERVER_ADDRESS + '/JsonRPC.aspx?id=186&method=TechnicianAPIver2.GetSettings&params='
    },
    FAUCET_EDIT: {
        BRANDS_LIMIT: 200
    }
};