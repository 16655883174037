import { useEffect, useState } from 'react';

import { CONFIGS } from '../utilities/Configs';
import { FormatDate, UrlQueryParam } from '../utilities/Helpers';
import { Faucet, Location, Part, PartContainer, PartGroup, UserSettings } from '../utilities/Types';

import IconAdd from '@material-ui/icons/Add';
import IconSettings from '@material-ui/icons/Settings';

import { FaucetEdit } from './FaucetEdit';
import { PartAdd } from './PartAdd';
import { PartEdit } from './PartEdit';

const imageKegL = 'images/keg-size-l.png';
const imageKegNone = 'images/keg-size-none.png';
const imageKegS = 'images/keg-size-s.png';

export const Welcome = () => {
	const [locations, setLocations] = useState<undefined | Array<Location>>(undefined);
	const [partGroups, setPartGroups] = useState<undefined | Array<PartGroup>>(undefined);
	const [selectedFaucet, setSelectedFaucet] = useState<undefined | Faucet>(undefined);
	const [selectedPart, setSelectedPart] = useState<undefined | Part>(undefined);
	const [selectedPartContainer, setSelectedPartContainer] = useState<undefined | PartContainer>(undefined);
	const [userSettings, setUserSettings] = useState<undefined | UserSettings>(undefined);
	
	useEffect(() => {
        fetch(CONFIGS.API_ENDPOINTS.SETTINGS_GET + btoa(JSON.stringify([UrlQueryParam('token'), UrlQueryParam('id')]))).then(
            (response) => response.json()
        ).then(
            (response) => setUserSettings(response['result'].length > 0 ? response.result[0] : undefined)
        ).catch(
            () => alert('An unexpected error has occurred. Please try again later. If problem persists, please contact support.')
        );
    }, []);
	
	useEffect(() => {
        fetch(CONFIGS.API_ENDPOINTS.PART_GROUPS_INVENTORIES_GET + btoa(JSON.stringify([UrlQueryParam('token'), UrlQueryParam('id')]))).then(
            (response) => response.json()
        ).then(
            (response) => setPartGroups(response['result'])
        ).catch(
            () => alert('An unexpected error has occurred. Please try again later. If problem persists, please contact support.')
        );
    }, []);
	
	useEffect(() => {
        fetchLocations();
	}, []);
	
	const fetchLocations = () => {
		fetch(CONFIGS.API_ENDPOINTS.LOCATIONS_GET + btoa(JSON.stringify([UrlQueryParam('token'), UrlQueryParam('id')]))).then(
            (response) => response.json()
        ).then(
            (response) => setLocations(response['result'])
        ).catch(
            () => alert('An unexpected error has occurred. Please try again later. If problem persists, please contact support.')
        );
	};

	const updateKegSize = (faucet) => {
		if (faucet['kid'] === null) {
			const post1 = [
				UrlQueryParam('id'),
				-1,
				(faucet['bid'] || -1),
				faucet['fid'],
				'0001-01-01',
				'0001-01-01',
				'0001-01-01',
				1
			];
			
			fetch(CONFIGS.API_ENDPOINTS.KEG_UPSERT + btoa(JSON.stringify([...[UrlQueryParam('token')], ...post1]))).then(
				(response1) => response1.json()
			).then(
				(response1) => {
					if (response1['error'] !== undefined) {
						alert('Error: ' + response1['error']['message']);
					}
					else {
						const post2 = [
							UrlQueryParam('id'),
							faucet['fid'],
							(faucet['did'] || -1),
							(faucet['bid'] || -1),
							response1['result']
						];
				
						fetch(CONFIGS.API_ENDPOINTS.FAUCET_UPDATE + btoa(JSON.stringify([...[UrlQueryParam('token')], ...post2]))).then(
							(response2) => response2.json()
						).then(
							(response2) => {
								if (response2['error'] !== undefined) {
									alert('Error: ' + response2['error']['message']);
								}
								else {
									fetchLocations();
								}
							}
						).catch(
							() => alert('An unexpected error has occurred. Please try again later. If problem persists, please contact support.')
						);
					}
				}
			).catch(
				() => alert('An unexpected error has occurred. Please try again later. If problem persists, please contact support.')
			);
		}
		else {
			const post = [
				UrlQueryParam('id'),
				faucet['kid'],
				(faucet['bid'] || -1),
				faucet['fid'],
				(faucet['kegborndate'] || '0001-01-01'),
				(faucet['expirationdate'] || '0001-01-01'),
				(faucet['tappeddate'] || '0001-01-01'),
				(faucet['keg_size'] === 'Small' ? 2 : 1)
			];
			
			fetch(CONFIGS.API_ENDPOINTS.KEG_UPSERT + btoa(JSON.stringify([...[UrlQueryParam('token')], ...post]))).then(
				(response) => response.json()
			).then(
				(response) => {
					if (response['error'] !== undefined) {
						alert('Error: ' + response['error']['message']);
					}
					else {
						fetchLocations();
					}
				}
			).catch(
				() => alert('An unexpected error has occurred. Please try again later. If problem persists, please contact support.')
			);
		}
	};
	
	return (
		(userSettings === undefined || locations === undefined) ? (
			<p>Loading...</p>
		) : (
			<>
				{
					locations.map((_location, i) => (
						<div key={i}>
							<div className="header">
								<p className="legend legend-blue">Stations</p>
								<p className="legend legend-green">Towers</p>
								<p className="legend legend-orange">Faucets</p>
							</div>
							{
								false && (
									<>
										<p className="heading margin-y">Other Parts</p>
										<p className="subheading mute">Equipment not assigned to the SmartSystem</p>
									</>
								)
							}
							{
								_location['Equipment'].map((_equipment, j) => (
									<div key={j}>
										<div className="row row-spread padding">
											<p className="title bold">{_equipment['Equipment Name']} ({_equipment['Parts'].length})</p>
											<IconAdd fontSize="medium" className="pointer" onClick={() => setSelectedPartContainer({ id: _equipment['Equipment ID'], type: 37 })} />
										</div>
										{
											_equipment['Parts'].map((_part, k) => (
												<div key={k} className="strip strip-blue pointer" onClick={() => setSelectedPart(_part)}>
													<p className="title grow">{k + 1}. {_part['Part Name']}</p>
												</div>
											))
										}
									</div>
								))
							}
							{
								false && (
									<>
										<p className="heading margin-y">SmartSystem &amp; Parts</p>
										<p className="subheading mute">Equipment assigned to the SmartSystem</p>
									</>
								)
							}
							{
								_location['System'].map((_system, j) => (
									<div key={j} className="container container-gray">
										<div className="row row-spread padding">
											<div>
												<p className="heading">{_system['name']}</p>
												<p className="subheading mute">{_system['typename']} with {_system['stations'].length} Stations</p>
											</div>
											<IconAdd fontSize="medium" className="pointer" onClick={() => setSelectedPartContainer({ id: _system['sysid'], type: 7 })} />
										</div>
										<div>
											{
												_system['parts'].map((_part, k) => (
													<div key={k} className="strip strip-blue pointer" onClick={() => setSelectedPart(_part)}>
														<p className="title grow">{k + 1}. {_part['Part Name']}</p>
													</div>
												))
											}
										</div>
										{
											_system['stations'].map((_station, k) => (
												<div key={k} className="container container-blue">
													<div className="row row-spread padding">
														<div>
															<p className="heading">{_station['name']}</p>
															<p className="subheading mute">Station with {_station['towers'].length} Towers</p>
														</div>
														<IconAdd fontSize="medium" className="pointer" onClick={() => setSelectedPartContainer({ id: _station['stid'], type: 8 })} />
													</div>
													<div>
														{
															_station['parts'].map((_part, l) => (
																<div key={l} className="strip strip-blue pointer" onClick={() => setSelectedPart(_part)}>
																	<p className="title grow">{l + 1}. {_part['Part Name']}</p>
																</div>
															))
														}
													</div>
													{
														_station['towers'].map((_tower, l) => (
															<div key={l} className="container container-green">
																<div className="row row-spread padding">
																	<div>
																		<p className="heading">{_tower['name']}</p>
																		<p className="subheading mute">Tower with {_tower['faucets'].length} Faucets</p>
																	</div>
																	<IconAdd fontSize="medium" className="pointer" onClick={() => setSelectedPartContainer({ id: _tower['tid'], type: 9 })} />
																</div>
																<div>
																	{
																		_tower['parts'].map((_part, m) => (
																			<div key={m} className="strip strip-blue pointer" onClick={() => setSelectedPart(_part)}>
																				<p className="title grow">{m + 1}. {_part['Part Name']}</p>
																			</div>
																		))
																	}
																</div>
																<div>
																	{
																		_tower['faucets'].map((_faucet, m) => (
																			<div key={m} className="container container-orange">
																				<div className="row row-spread padding">
																					<div className="grow">
																						<div className="row row-spread">
																							<div className={'grow' + (userSettings['enable_kegs'] === true ? ' pointer' : '')} onClick={userSettings['enable_kegs'] === true ? () => setSelectedFaucet(_faucet) : undefined}>
																								{
																									_faucet['brand'].trim() === '' ? (
																										<p className="title mute">{m + 1}. No name</p>
																									) : (
																										<p className="title">{m + 1}. {_faucet['brand'].trim()}</p>
																									)
																								}
																								<p className="subtitle">{_faucet['distributor']}</p>
																								{
																									userSettings['enable_kegs'] === true && (
																										<p className="subtitle">{_faucet['keg']}</p>
																									)
																								}
																							</div>
																							<div className="row row-spread">
																								{
																									_faucet['kegborndate'] !== null && (
																										<p className="subtitle">{FormatDate(new Date(_faucet['kegborndate']))}</p>
																									)
																								}
																								<img
																									src={
																										(() => {
																											switch(_faucet['keg_size']) {
																												case 'Small':
																													return imageKegS;

																												case 'Large':
																													return imageKegL;

																												default:
																													return imageKegNone;
																											}
																										})()
																									}
																									alt="Keg Size"
																									className={userSettings['enable_kegs'] === true ? 'pointer' : undefined}
																									onClick={userSettings['enable_kegs'] === true ? () => updateKegSize(_faucet) : undefined}
																								/>
																								<IconAdd fontSize="medium" className="pointer" onClick={() => setSelectedPartContainer({ id: _faucet['fid'], type: 10 })} />
																								<IconSettings fontSize="medium" className={_faucet['serviceable'] === false ? 'mute' : ''} />
																							</div>
																						</div>
																						<div>
																							{
																								_faucet['parts'].map((_part, n) => (
																									<div key={n} className="strip strip-blue pointer" onClick={() => setSelectedPart(_part)}>
																										<p className="title grow">{n + 1}. {_part['Part Name']}</p>
																									</div>
																								))
																							}
																						</div>
																					</div>
																				</div>
																			</div>
																		))
																	}
																</div>
															</div>
														))
													}
												</div>
											))
										}
									</div>
								))
							}
						</div>
					))
				}
				{
					partGroups !== undefined && (
						<>
							{
								selectedPartContainer !== undefined && (
									<PartAdd container={selectedPartContainer} partGroups={partGroups} onAdd={fetchLocations} onClose={() => setSelectedPartContainer(undefined)} />
								)
							}
							{
								selectedPart !== undefined && (
									<PartEdit part={selectedPart} partGroups={partGroups} onEdit={fetchLocations} onClose={() => setSelectedPart(undefined)} />
								)
							}
						</>
					)
				}
				{
					selectedFaucet !== undefined && (
						<FaucetEdit faucet={selectedFaucet} onEdit={fetchLocations} onDeleteKeg={fetchLocations} onClose={() => setSelectedFaucet(undefined)} />
					)
				}
			</>
		)
	);
};