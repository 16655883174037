import React from 'react';

import { Welcome } from './components/Welcome';

import './styles/App.css';
import './styles/MaterialUi.css';

export default function App() {
	return (
		<Welcome />
	);
};